.primary-button-container {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.primary-button-button {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  align-self: center;
  font-style: normal;
  transition: 0.3s;
  font-family: Palanquin;
  font-weight: 500;
  padding-top: 12px;
  border-width: 0px;
  padding-left: 32px;
  border-radius: var(--dl-radius-radius-radius6);
  padding-right: 32px;
  padding-bottom: 12px;
  text-transform: none;
  text-decoration: none;
  background-color: var(--dl-color-purple-1001);
}
.primary-button-root-class-name {
  align-self: center;
}
