:root {
  --dl-color-blue-500: #7099d7ff;
  --dl-color-blue-800: #4077c9ff;
  --dl-color-gray-300: #d9dbe1ff;
  --dl-color-gray-700: #969babff;
  --dl-color-gray-800: #474a57ff;
  --dl-color-gray-900: #18191fff;
  --dl-color-pink-100: #ffb1e6ff;
  --dl-color-pink-800: #ff72d2ff;
  --dl-size-size-unit: 1rem;
  --dl-color-blue-1001: #a0bbe4ff;
  --dl-color-green-100: #cee3a1ff;
  --dl-color-green-800: #b5d572ff;
  --dl-color-gray-black: #000000ff;
  --dl-color-gray-white: #FFFFFF;
  --dl-color-green-1000: #9dc743ff;
  --dl-color-orange-100: #ffe3c1ff;
  --dl-color-orange-800: #ffc278ff;
  --dl-color-purple-100: #c0b3e5ff;
  --dl-color-purple-800: #8e76d0ff;
  --dl-color-purple-900: #7558c6ff;
  --dl-space-space-unit: 1rem;
  --dl-color-purple-1001: #452d85ff;
  --dl-color-blue-default: #2f60a7ff;
  --dl-color-pink-default: #f22bb2ff;
  --dl-size-size-halfunit: 0.5rem;
  --dl-color-otherblue-100: #92d5f2ff;
  --dl-color-otherblue-800: #5cc0ebff;
  --dl-radius-radius-round: 50%;
  --dl-color-orange-default: #fe9a22ff;
  --dl-color-otherblue-1000: #1993c8ff;
  --dl-size-size-doubleunit: 2rem;
  --dl-size-size-tripleunit: 3rem;
  --dl-space-space-halfunit: 0.5rem;
  --dl-color-otherpurple-100: #c3bcdcff;
  --dl-color-otherpurple-500: #9e94c7ff;
  --dl-color-otherpurple-800: #8679b9ff;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius6: 6px;
  --dl-radius-radius-radius8: 8px;
  --dl-color-otherpurple-1000: #6455a3ff;
  --dl-space-space-doubleunit: 2rem;
  --dl-space-space-tripleunit: 3rem;
}
.teleport-show {
  display: flex !important;
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.button {
  color: var(--dl-color-gray-900);
  display: inline-block;
  padding: 0.5rem 1rem;
  font-family: Inter;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list-item {
  display: list-item;
}
.input {
  color: var(--dl-color-gray-900);
  cursor: auto;
  padding: 0.5rem 1rem;
  font-family: Inter;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-900);
  cursor: auto;
  padding: 0.5rem;
  font-family: Inter;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.largeLabel {
  font-size: 20px;
  font-family: Palanquin Dark;
  font-weight: 600;
  text-transform: none;
  text-decoration: none;
}
.body2 {
  font-size: 14px;
  font-family: Palanquin Dark;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.lead1 {
  font-size: 18px;
  font-family: Palanquin;
  font-weight: 400;
  line-height: 1.77;
  text-transform: none;
  text-decoration: none;
}
.headline6 {
  font-size: 20px;
  font-family: Palanquin;
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.body1 {
  font-size: 16px;
  font-family: Palanquin;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.lead2 {
  font-size: 14px;
  font-family: Palanquin;
  font-weight: 500;
  line-height: 1.77;
  text-transform: none;
  text-decoration: none;
}
.headline3 {
  font-size: 40px;
  font-family: Palanquin;
  font-weight: 800;
  text-transform: none;
  text-decoration: none;
}
.headline2 {
  font-size: 48px;
  font-family: Palanquin;
  font-weight: 800;
  text-transform: none;
  text-decoration: none;
}
.headline5 {
  font-size: 24px;
  font-family: Palanquin;
  font-weight: 600;
  text-transform: none;
  text-decoration: none;
}
.subtitle2 {
  font-size: 18px;
  font-family: Palanquin;
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.smallLabel {
  font-size: 12px;
  font-family: Palanquin Dark;
  font-weight: 600;
  text-transform: none;
  text-decoration: none;
}
.mediumLabel {
  font-size: 14px;
  font-family: Palanquin;
  font-weight: 600;
  text-transform: none;
  text-decoration: none;
}
.headline1 {
  font-size: 50px;
  font-family: Palanquin Dark;
  font-weight: 400;
  line-height: 1.2;
  text-transform: none;
  text-decoration: none;
}
.badgeLabel {
  font-size: 20px;
  font-family: Nanum Pen Script;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.subtitle1 {
  font-size: 18px;
  font-family: Palanquin;
  font-weight: 700;
  text-transform: none;
  text-decoration: none;
}
.headline4 {
  font-size: 28px;
  font-family: Palanquin;
  font-weight: 800;
  text-transform: none;
  text-decoration: none;
}
.capitalised {
  font-size: 14px;
  font-family: Palanquin Dark;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
}
