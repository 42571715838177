.home-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-main {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-hero {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-video {
  width: 60%;
  height: 60%;
  position: relative;
  align-self: center;
}
.home-container01 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 60%;
  bottom: 0px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text {
  width: max-content;
  height: max-content;
  font-size: 50px;
  text-align: center;
  font-family: Palanquin Dark;
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text01 {
  text-align: left;
  font-family: Palanquin Dark;
  font-weight: 800;
  text-transform: none;
  text-decoration: none;
}
.home-text02 {
  color: var(--dl-color-gray-white);
}
.home-text03 {
  color: var(--dl-color-gray-300);
}
.home-text04 {
  color: #fbb0b0;
}
.home-text05 {
  color: var(--dl-color-gray-white);
  text-align: left;
}
.home-text07 {
  color: var(--dl-color-gray-white);
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text09 {
  max-width: 500px;
  text-align: center;
}
.home-text10 {
  white-space: pre-wrap;
}
.home-features1 {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-clients {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: hidden;
  position: relative;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-divider {
  top: 0px;
  left: auto;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-image {
  height: 40px;
  object-fit: cover;
}
.home-image01 {
  height: 40px;
  object-fit: cover;
}
.home-image02 {
  height: 40px;
  object-fit: cover;
}
.home-image03 {
  height: 40px;
  object-fit: cover;
}
.home-image04 {
  height: 40px;
  object-fit: cover;
}
.home-divider1 {
  top: auto;
  left: auto;
  right: auto;
  width: 100%;
  bottom: 0px;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.home-testimonials {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: center;
  background-color: var(--dl-color-blue-500);
}
.home-container02 {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container03 {
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.home-image05 {
  top: -22px;
  left: -65px;
  right: auto;
  width: 100px;
  bottom: auto;
  z-index: 10;
  position: absolute;
  object-fit: cover;
}
.home-text11 {
  z-index: 100;
  max-width: 500px;
}
.home-container04 {
  flex: 0 0 auto;
  width: 350px;
  display: flex;
  align-self: flex-end;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container05 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.home-container06 {
  width: 350px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container07 {
  width: 300px;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  flex-direction: column;
}
.home-feature1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container08 {
  flex: 0 0 auto;
  width: 540px;
  height: 540px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-purple-100);
}
.home-image06 {
  width: 360px;
  margin-top: 100px;
  object-fit: cover;
}
.home-container09 {
  flex: 1;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text14 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text15 {
  white-space: pre-wrap;
}
.home-feature2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container10 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-text17 {
  white-space: pre-wrap;
}
.home-text18 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text19 {
  white-space: pre-wrap;
}
.home-image07 {
  width: 100%;
  height: 400px;
  margin-top: var(--dl-space-space-tripleunit);
  object-fit: cover;
}
.home-c-t-a {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-blue-default);
}
.home-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-container12 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text21 {
  white-space: pre-wrap;
}
.home-text22 {
  max-width: 500px;
}
.home-text23 {
  white-space: pre-wrap;
}
.home-text24 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text25 {
  white-space: pre-wrap;
}
.home-container13 {
  width: 520px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-image08 {
  top: -100px;
  left: auto;
  right: var(--dl-space-space-doubleunit);
  width: 232px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.home-image09 {
  top: auto;
  left: var(--dl-space-space-doubleunit);
  right: auto;
  width: 232px;
  bottom: -100px;
  position: absolute;
  object-fit: cover;
}
@media(max-width: 991px) {
  .home-video {
    width: 70%;
    height: 70%;
    position: relative;
    align-self: center;
  }
  .home-container01 {
    top: 0px;
    left: 0px;
    right: 0px;
    width: 70%;
    bottom: 0px;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
  .home-text {
    width: max-content;
    height: max-content;
    font-size: 300%;
    align-self: center;
  }
  .home-container02 {
    flex-direction: column;
  }
  .home-container04 {
    align-self: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-container05 {
    align-items: center;
    margin-left: 0px;
  }
  .home-container07 {
    width: 350px;
  }
  .home-feature1 {
    flex-direction: column;
  }
  .home-container09 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-container10 {
    align-items: flex-start;
    flex-direction: column;
  }
  .home-container13 {
    display: none;
  }
}
@media(max-width: 767px) {
  .home-video {
    width: 80%;
    height: 80%;
    position: relative;
    align-self: center;
  }
  .home-container01 {
    top: 0px;
    left: 0px;
    right: 0px;
    width: 80%;
    bottom: 0px;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
  .home-text {
    width: max-content;
    height: max-content;
    font-size: 250%;
    align-self: center;
  }
  .home-text08 {
    font-size: 40px;
  }
  .home-clients {
    justify-content: center;
  }
  .home-image {
    margin: var(--dl-space-space-unit);
  }
  .home-image01 {
    margin: var(--dl-space-space-unit);
  }
  .home-image02 {
    margin: var(--dl-space-space-unit);
  }
  .home-image03 {
    margin: var(--dl-space-space-unit);
  }
  .home-image04 {
    margin: var(--dl-space-space-unit);
  }
  .home-container03 {
    align-items: center;
  }
  .home-image05 {
    top: -2px;
    left: 21px;
    right: auto;
    width: 50px;
    bottom: auto;
  }
  .home-text11 {
    font-size: 40px;
    text-align: center;
  }
  .home-text16 {
    font-size: 40px;
  }
  .home-text20 {
    font-size: 40px;
  }
}
@media(max-width: 479px) {
  .home-video {
    width: 90%;
    height: 90%;
    position: relative;
    align-self: center;
  }
  .home-container01 {
    top: 0px;
    left: 0px;
    right: 0px;
    width: 90%;
    bottom: 0px;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
  .home-text {
    width: max-content;
    height: max-content;
    font-size: 220%;
    align-self: center;
  }
  .home-text08 {
    text-align: center;
  }
  .home-text09 {
    text-align: center;
  }
  .home-container08 {
    width: 100%;
  }
}
