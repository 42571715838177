.store-banner-store-banner {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.store-banner-image {
  width: 135px;
  object-fit: cover;
}
.store-banner-image1 {
  width: 135px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
}
